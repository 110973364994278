import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import { Container, Row, Col } from 'react-grid-system';
import Head from 'components/head';
import Title from 'components/title';
import Logo from 'components/logo';
import Link from 'components/link';
import { withPrefix } from 'gatsby-link';
import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Wrapper = styled.div`
  button {
    margin-bottom: 2rem;
    width: 100%;
  }
  ${MEDIA.MIN_LAPTOP`
    button {
      float: left;
      margin-right: 4%;
      width: 48%;
    }
    p {}
  `};
`;

export const ButtonStyle = styled.button`
  font-size: 14px;
  text-align: center;
  color: #ffffff;
  outline: none;
  padding: 10px 0;
  box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.1);
  background-color: ${props => props.theme.colors.primary};
  border-color: ${props => props.theme.colors.primary};
  border-width: 5px;
  border-radius: 6px;
  letter-spacing: 1.5px;
  cursor: pointer;
  &:hover,
  &:focus {
    background-color: transparent;
    color: ${props => props.theme.colors.primary};
  }
`;

const centerItems = {
  textAlign: 'center',
};

const Contact = ({ data }) => (
  <Layout>
    <Head pageTitle={data.contactJson.title} />
    <Container>
      <Row>
        <Col col={12} sm={12}>
          <div style={centerItems}>
            <Logo />
            <Title as="h1" size="large">
              {data.contactJson.title}
            </Title>
          </div>
        </Col>
      </Row>
    </Container>
    <div style={{ height: '5vh' }} />
    <Container>
      <Row justify="center">
        <Col col={12} sm={12} md={8}>
          <div
            dangerouslySetInnerHTML={{
              __html: data.contactJson.aside.childMarkdownRemark.html,
            }}
          />
        </Col>
      </Row>
      <Row justify="center">
        <Col col={12} sm={12} md={8}>
          <div
            dangerouslySetInnerHTML={{
              __html: data.contactJson.content.childMarkdownRemark.html,
            }}
          />

          <form
            id="contact"
            className="formspree"
            style={{ marginTop: '2rem', marginBottom: '4rem' }}
            name="contact"
            action="https://formspree.io/info@tradeincool.com"
            method="POST"
          >
            <input type="hidden" name="form-name" value="contact" />
            <input
              type="hidden"
              name="bot-field"
              value="info@tradeincool.com"
            />
            <input type="hidden" name="_subject" value="Contact Form" />
            <input name="_next" value={withPrefix('/thanks')} type="hidden" />

            <Row>
              <Col md={6} className="form-group">
                <label id="label-firstname" htmlFor="firstname">
                  First Name <span>(required)</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="firstname"
                  name="firstname"
                  placeholder="Arya"
                  autoComplete="off"
                  autoCorrect="off"
                  spellCheck="false"
                  required
                />
              </Col>

              <Col md={6} className="form-group">
                <label id="label-lastname" htmlFor="lastname">
                  Last Name <span>(required)</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="lastname"
                  name="lastname"
                  placeholder="Stark"
                  autoComplete="off"
                  autoCorrect="off"
                  spellCheck="false"
                  required
                />
              </Col>
            </Row>

            <div className="form-group">
              <label htmlFor="_replyto">
                Email <span>(required)</span>
              </label>
              <input
                type="email"
                className="form-control"
                id="_replyto"
                name="_replyto"
                placeholder="arya.stark@winterfell.io"
                autoComplete="off"
                autoCorrect="off"
                spellCheck="false"
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="usrtel">Phone Number</label>
              <input
                type="tel"
                className="form-control"
                id="usrtel"
                name="usrtel"
                placeholder="Enter Telephone"
                autoComplete="off"
                autoCorrect="off"
                spellCheck="false"
              />
            </div>

            <div className="form-group">
              <label htmlFor="message">
                Tell us about your project <span>(required)</span>
              </label>
              <textarea
                rows="5"
                className="form-control"
                id="message"
                name="message"
                placeholder="What is the current state of your project? What are you looking to change? What is your timeline?"
                autoComplete="off"
                autoCorrect="off"
                required
              />
            </div>

            <Wrapper className="form-group justify-content-end">
              <ButtonStyle type="submit" id="send">
                Start the Conversation
              </ButtonStyle>
              <p>
                or email us at{' '}
                <Link
                  href="mailto:hello@tradeincool.com"
                  page="mailto:hello@tradeincool.com"
                >
                  hello@tradeincool.com
                </Link>
              </p>
            </Wrapper>
          </form>
        </Col>
      </Row>
    </Container>
  </Layout>
);

Contact.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Contact;

export const query = graphql`
  query ContactQuery {
    contactJson {
      title
      content {
        childMarkdownRemark {
          html
        }
      }
      aside {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;
